.article[data-mode="grand"],
.colonnes-principales.une-colonne-en-mobile > .colonne:first-child>.article[data-mode="auto"]:first-child {
	--mode-article: 'grand';
	figure[data-zone="photo"] {
		img {
			&[data-mode-article]:not([data-mode-article*="grand"]) {
				display: none;
			}
			display: block;
		}
	}

	.cdf {
		display: none;
	}

	margin: 0;
	border-bottom: var(--border-size-1) solid var(--color-text-default);

	@include moins-que-moyen {
		@include marginInline(calc(var(--marge-exterieure-grille) * -1));
	}

	// margin-bottom: var(--marge-grande);

	@include grande-grille {
		margin-left: calc(var(--marge-exterieure-grille) * -1);
	}

	display: flex;
	flex-direction: column;
	min-height: 80vh;

	@include moyen {
		min-height: 75vh;
		--decalage-focal-x: 50%;
	}




	figure[data-zone="photo"] {
		z-index: -1;


		@include tres-grand {
			overflow: hidden;

			img {
				transform-origin: center;
				scale: 1.05;
			}
		}

		&,
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			max-height: unset;
			z-index: auto;
		}

		>figcaption {
			text-align: right;
		}

	}

	&:before {
		content: '';
		flex: 1;
	}

	>div {
		background: linear-gradient(to top, black 0%, rgba(0, 0, 0, .6) 50%, rgba(0, 0, 0, 0) 100%);
		position: relative;
		// z-index: 1;
		width: 100%;
		padding: var(--marge-exterieure-grille) 10%;
		padding-top: var(--marge-exterieure-grille);
		box-sizing: border-box;

		p {
			color: var(--color-light);
			font-weight: var(--font-weight-medium);
			// line-height: var(--hauteur-ligne-petite);
			line-height: 1.35em;
		}

		header {
			>* {
				color: var(--color-light);
			}

			
			>.titre {
				color: var(--color-light);
				font-size: var(--taille-texte-tres-grand);
			}
		}

		/*        &:after {
            z-index: 0;
            position: absolute;
            content: '';
            inset: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

        }*/

		ul.article-actions {
			--couleur-svg-fond: var(--color-brand-alt);
			--couleur-svg-texte: var(--color-brand);

			>li>a>svg {
				width: 44px;
				height: 32px;
							// color: var(--color-brand);

				// // path {
				// 	fill: var(--color-light);
				// // }

				// text {
				// 	fill: var(--color-light);
				// }

			}
		}
	}


	.ul.chemin-de-fer {
		flex-wrap: nowrap;

		li::before {
			color: var(--color-brand-alt);
		}
	}

	@include moyen {
		&:before {
			display: none;
		}

		>div {
			header {
				>.titre {
					font-size: var(--taille-texte-grand-smart);
					letter-spacing: -0.97px;
				}
			}

			flex: 1;
			width: 60%;
			height: 100%;
			background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.35) 60%, rgba(0, 0, 0, 0) 100%);

			// &:after {
			//     background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
			// }
		}

		&:has([data-alignement="gauche"]) {
			align-items: end;

			@include moyen {
				--decalage-focal-x: 0%;
			}

			>div {
				background: linear-gradient(to left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.35) 60%, rgba(0, 0, 0, 0) 100%);

			}
		}

	}

}