@import "../inc/base";


[data-zone="apercu-breve"] {
	content: 'ok';
	margin-bottom: var(--marge);
	transition: max-height .5s;
	max-height: 0;
	overflow: hidden;
	height: auto;

	&[data-etat="reduit"] {
		max-height: 100vh;
	}

	position: relative;

	>div:last-child {
		position: absolute;
		bottom: 0;
		inset-inline: 0;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		text-align: right;
		padding-top: var(--marge-tres-grande);
	}
}

[data-zone="breves"] {

	article.article {
		margin-top: var(--marge-petite);
	}
	.article[data-id] {
		>a {
			outline: 0;
		}

		header>h2.titre {
			// todo responsive
			display: none;
		}


	}

	h2 {
		// margin: var(--marge) 0;
		margin: 0;
		text-align: center;
		display: none;
	}

	.article-actions {
		display: none;
	}

	@include moins-que-grand {
		.groupe-de-breves[data-nb]:not([data-nb="1"]) {
			display: none;
		}

		.groupe-de-breves[data-nb="1"] {
			.article:not(:nth-child(-n+3)) {
				display: none;
			}
		}
	}

	@include grand {
		h2 {
			display: block;
			color: var(--color-brand);
			// font-size: clamp(1.5rem, 3vw, 2.8rem);
			font-size: var(--taille-texte-smart);
			// margin-top: var(--marge);
			margin-bottom: 0;

			@include grand {
				// font-size: clamp(1.5rem, 6vw, 3rem);
				font-size: var(--taille-texte-smart);
				;
			}
		}
	}
}