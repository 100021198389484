small {
  font-size: var(--taille-texte-tout-petit);
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

i,
em {
  font-style: italic;
}

var {
  font-style: normal;
}
sup {
	font-weight: var(--font-weight-medium);
}

abbr[title] {
  border-bottom: var(--border-size-1) dotted var(--color-border);
  cursor: help;
}

dfn {
    font-style: italic;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}

/* type-columns */

@media screen and (min-width: 64em) {
  .type-columns {
    column-width: 16em;
    column-gap: var(--marge);
  }

  .type-columns__span {
    column-span: all;
  }

  .type-columns h3 {
    break-after: avoid;
    break-before: avoid;
  }

  .type-columns p:not(.lede) {
    margin-bottom: var(--marge-tres-micro);
    font-size: var(--taille-texte-petit);
  }

  .type-columns p:not(.lede) + p:not(.lede) {
    text-indent: 1em;
  }
}

/* type-indent */

.type-indent p:not(.lede) {
  margin-bottom: var(--marge-tres-micro);
  font-size: var(--taille-texte-petit);
}

.type-indent p:not(.lede) + p:not(.lede) {
  text-indent: 1em;
}

/* type-sizes */

.type-display {
  font-size: var(--taille-texte-smart);
  line-height: var(--hauteur-ligne-petite);
}

.type-x-display {
  font-size: var(--taille-texte-grand-smart);
  line-height: var(--hauteur-ligne-petite);
}

.type-xx-display {
  font-size: var(--taille-texte-grand-smart);
  line-height: var(--hauteur-ligne-petite);
}

/* type-uppercase */

.type-outline {
  color: var(--color-brand);
  letter-spacing: -0.025em;
  -webkit-text-fill-color: var(--color-brand);
  -webkit-text-stroke: 2px var(--color-text-default);
}

/* type-uppercase */

.type-uppercase {
  text-transform: uppercase;
}

/* drop cap */

.type-dropcap:first-letter {
  color: var(--color-brand);
  float: left;
  font-size: var(--taille-texte-grand-smart);
  line-height: 60px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}

/* display numbers */
.type-numbers {
  font-family: "Venn Bold";
  color: var(--color-text-default);
  font-size: var(--taille-texte-grand-smart);
}

.type-numbers li:before {
  content: "";
}

.type-numbers li {
  display: inline-block;
}

.type-numbers li:first-child {
  background-color: var(--color-brand);
  color: var(--color-text-alt);
  padding: 0.5rem 1.2rem;
  line-height: 1;
}

/* Block Date */

.block-date {
  display: flex;
  flex-direction: column;
  font-family: "Venn Bold";
  align-items: center;
  font-size: var(--taille-texte-tout-petit);
  text-transform: uppercase;
  flex: 0 0 80px;
}

.block-date span {
  line-height: 1;
}

.block-date span:before {
  content: "";
}

.block-date span:nth-child(1) {
  font-size: 3em;
}

.block-date span:nth-child(2) {
  color: var(--color-brand);
  font-size: 1.9em;
}

.block-date span:nth-child(3) {
  line-height: 1.6;
  font-size: 1.4em;
	font-weight: var(--font-weight-medium);
}

.spaced-title {
  font-family: var(--police-sans-serif);
  margin-bottom: var(--marge-grande);
}
