// .article {
//     display: none !important;
// }
body:has(#stories-slider-dialog[open]) .habillage {
    display: none !important;
}
.stories-slider-wrapper {
    interpolate-size: allow-keywords;

    [name="stories"] {
        position: absolute;
        top: -2rem
    }

    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--marge);
    margin-bottom: -100px;

    >* {
        margin: 0;
    }

    h2 {
        padding: var(--marge-mini);
        background-color: var(--color-brand);
        color: var(--color-light);
    }
}

#stories-slider-dialog {
    border: 0;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: transparent;
    overscroll-behavior: none;

    &[data-muted="true"] .principale:after {
        width: var(--icone);
        height: var(--icone);
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' fill='white' viewBox='5 3 90 90'%3E%3Cpath d='M93 33 63 63M64.2 64.2l-2.4-2.4c-.9-.9-.9-2.3 0-3.2l26.8-26.8c.9-.9 2.3-.9 3.2 0l2.4 2.4c.9.9.9 2.3 0 3.2L67.5 64.2c-.9.9-2.4.9-3.3 0zM63 33l30 30'/%3E%3Cpath d='m91.8 64.2 2.4-2.4c.9-.9.9-2.3 0-3.2L67.5 31.8c-.9-.9-2.3-.9-3.2 0l-2.4 2.4c-.9.9-.9 2.3 0 3.2l26.8 26.8c.7.9 2.2.9 3.1 0zM50 17.4 20.7 36.3c-.3.2-.5.4-.7.7H8.9C6.8 37 5 38.8 5 40.9v14.2C5 57.2 6.8 59 8.9 59H20c.2.3.4.5.7.7L50 78.6c1.7 1.1 4-.2 4-2.3V19.7c0-2.1-2.3-3.4-4-2.3z'/%3E%3C/svg%3E");
        content: '';
        bottom: var(--marge);
        left: var(--marge);
    }

    >section {
        overflow-x: auto;
        &:not([data-dragging="true"]) {
            scroll-snap-type: x mandatory;
        }
        width: 100%;
        height: 100%;
        overscroll-behavior: none;
        padding-bottom: 200px;

        >div {
            display: flex;
            gap: var(--marge);
            height: 100%;

            >article {
                >a {
                    width: var(--icone);
                    height: var(--icone);
                    position: absolute;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='3 3 59 59'%3E%3Cpath d='M57 30.5a4.51 4.51 0 0 0-4.5 4.5v17.5h-41v-41H29a4.5 4.5 0 0 0 0-9H7A4.51 4.51 0 0 0 2.5 7v50A4.51 4.51 0 0 0 7 61.5h50a4.51 4.51 0 0 0 4.5-4.5V35a4.51 4.51 0 0 0-4.5-4.5Z'/%3E%3Cpath d='M60 2.5H43.13a4.5 4.5 0 0 0 0 9h3L34 23.68a1.49 1.49 0 0 0 0 2.12l4.2 4.2a1.5 1.5 0 0 0 2.12 0L52.5 17.87v3a4.5 4.5 0 1 0 9 0V4A1.5 1.5 0 0 0 60 2.5Z'/%3E%3C/svg%3E");
                    top: var(--marge);
                    left: var(--marge);
                }

                &:empty {
                    visibility: hidden;
                }

                border-radius: 15px;
                overflow: clip;
                cursor: default;

                @include moyen {
                    scale: 1;
                    transition: scale .1s ease-in-out;

                    &:not(.principale) {
                        cursor: pointer;
                        scale: 0.8;
                    }

                    aspect-ratio: 0.562;
                }

                @include moins-que-moyen {
                    flex: 0 0 100%;
                }

                display: flex;
                justify-content: center;
                align-items: center;
                background: black no-repeat center center;
                // background-size: min(128px,20vh);
                // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 100 100'%3E%3Cpath fill='%23fff' d='M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' dur='1s' from='0 50 50' repeatCount='indefinite' to='360 50 50' type='rotate'/%3E%3C/path%3E%3C/svg%3E%0A");
                scroll-snap-align: start;
                position: relative;

                svg {
                    position: absolute;
                    bottom: var(--marge);
                    left: var(--marge);
                    width: var(--icone-smart);
                    height: var(--icone-smart);
                }

                height: 100%;

                video {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.8);
    }

    &:before {
        z-index: 9;
        background-color: black;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-30%, 30%);
        position: absolute;
        top: 0;
        right: 0;
        content: '✖';
        color: white;
        font-size: 1rem;
        cursor: pointer;
    }
}

.stories-slider {

    // dialog {
    //     &:before {
    //         z-index: 9;
    //         background-color: black;
    //         width: 32px;
    //         height: 32px;
    //         border-radius: 50%;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         transform: translate(-30%, 30%);
    //         position: absolute;
    //         top: 0;
    //         right: 0;
    //         content: '✖';
    //         color: white;
    //         font-size: 1rem;
    //         cursor: pointer;
    //     }

    //     aspect-ratio: 0.562;
    //     max-height: 95vh;
    //     height: 100%;
    //     overflow: hidden;

    //     video {
    //         position: absolute;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: contain;
    //     }

    //     [data-loading="true"] {
    //         >* {
    //             opacity: 0;
    //         }
    //     }

    //     border: 0;
    //     padding: 0;
    //     border-radius: 15px;

    //     div {
    //         width: 100%;
    //         height: 100%;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //     }

    //     &::backdrop {
    //         background-color: rgba(0, 0, 0, 0.5);
    //     }

    //     nav {
    //         position: fixed;
    //         bottom: var(--marge);
    //         left: 0;
    //         // transform: translateY(-50%);
    //         width: calc(100% - 2 * var(--marge));
    //         display: flex;
    //         justify-content: space-between;
    //         height: 48px;
    //         padding: var(--marge);

    //         button {
    //             &[data-action=prev] {
    //                 transform: rotate(180deg);
    //             }

    //             height: 100%;
    //             aspect-ratio: 1;
    //             background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 252.6 213"><path fill="white" d="M252.6 107L146.6 0H82.2l83.2 83.2H0v46.6h165.4L82.2 213h64.4l106-106z"/></svg>') no-repeat;
    //             background-size: contain;
    //             filter:drop-shadow(0 20px 20px rgba(0, 0, 0, 0.5))
    //         }
    //     }
    // }

    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-bottom: 100px;

    div {
        display: flex;
        gap: var(--marge);

        article {
            &:empty {
                display: none;
            }

            border-radius: 15px;
            overflow: hidden;

            &:not(:empty) {
                background-color: black;
            }

            scroll-snap-align: start;

            min-width: 325px;

            aspect-ratio: 0.562 !important;

            position: relative;

            &.text {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: var(--color-light);
            }

            >*:not(img):not(p) {
                margin: 0 !important;
                min-width: initial !important;
                max-width: initial !important;
                min-height: initial !important;
                max-height: initial !important;
                height: 100% !important;
                height: 100% !important;
                position: absolute;
                inset: 0;
            }

            p {
                position: absolute;
                bottom: 0;
                padding: var(--marge);
                background: rgba(0, 0, 0, 0.5);
                margin: 0;
                color: white;

                span {
                    display: block;
                    white-space: pre-line;
                    height: 2lh;
                    transition: height .5s ease-in-out;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                font-size: var(--taille-texte-petit);
                font-family: sans-serif;
            }

            &:hover {
                p span {
                    height: auto;
                }
            }

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
            }

            svg {
                position: absolute;
                bottom: var(--marge);
                left: var(--marge);
                width: 32px;
                height: 32px;
            }

        }
    }
}